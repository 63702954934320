import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { QuestionCircleFill } from 'react-bootstrap-icons';

import PageLayout from '@components/layout/PageLayout';
import PageHero from '@components/layout/PageHero';
import FAQItem from '@components/FAQItem';
import * as styles from '@css/modules/faq.module.scss';
import { useMPEvent } from '@util/mixpanel';
import { Accordion } from '@src/components/Accordion';

export default function FAQ({ data }) {
  const {
    node_locale: nodeLocale,
    pageTitle,
    header,
    subTitle,
    sections,
  } = data.contentfulFaqPage;
  const { trackPageLoad } = useMPEvent();

  useEffect(() => {
    trackPageLoad({
      language: nodeLocale.toLowerCase(),
      url: `/${nodeLocale}/faq`,
      url_name: 'faq',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout node_locale={nodeLocale} pageTitle={pageTitle}>
      <PageHero data={{ header }} />

      <div className={styles.background}>
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <p className={`p-3 mb-5 rounded ${styles.subTitle}`}>
                {subTitle}
              </p>

              {sections.map((section, sectionIndex) => (
                <div
                  className="mb-5 justify-content-md-center"
                  key={`faq-section-${sectionIndex}`}
                >
                  <h2 className="h4 font-weight-bold mb-3">{section.header}</h2>
                  {section.questions.map((question) => (
                    <Accordion
                      key={`question-${question.id}`}
                      className="mb-3"
                      HeaderIcon={
                        <QuestionCircleFill
                          size={22}
                          className={`${styles.questionMark} mr-2`}
                        />
                      }
                      header={question.question}
                      body={
                        <div className={styles.faqItem}>
                          <FAQItem
                            html={question.answer?.childMarkdownRemark.html}
                            quote={
                              question.faqTestimonial?.childMarkdownRemark.html
                            }
                            source={question.faqTestimonialSource}
                          />
                        </div>
                      }
                    />
                  ))}
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query($node_locale: String!) {
    contentfulFaqPage(node_locale: { eq: $node_locale }) {
      node_locale
      pageTitle
      header
      subTitle
      sections {
        header
        questions {
          id
          question
          faqTestimonial {
            childMarkdownRemark {
              html
            }
          }
          faqTestimonialSource
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
