import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as styles from '@css/modules/faq.module.scss';
import Quote from '@css/img/svg/left-quote.svg';

export default function FAQItem({ html, quote, source }) {
  return (
    <Container className={styles.item}>
      <Row>
        <Col xs={12} md={quote ? 6 : 12}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Col>
        {quote ? (
          <Col md={6}>
            <div className={styles.quote}>
              <Row>
                <Col xs={2} className="px-0 pl-2">
                  <Quote className={`text-secondary ${styles.quotes}`} />
                </Col>
                <Col xs={10}>
                  <blockquote>
                    <div dangerouslySetInnerHTML={{ __html: quote }} />
                    {source ? (
                      <footer className={styles.source}>— {source}</footer>
                    ) : null}
                  </blockquote>
                </Col>
              </Row>
            </div>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
}
